<template>
  <div class="stats-wrapper" :class="[channelType, activeGameLayout.toLowerCase()]">
    <div class="toggle-bet-stats">
      <div class="bet-stats" v-if="bets['7'].value">
        <div class="title"
             :title="bets['7'].name">
          {{ bets['7'].name }}
        </div>
        <div class="subtitle">
          <span>{{ translations.general_first_ball }}</span>
        </div>
        <vue-circle
          :progress="Number(stats.firstBallOddEven.even)"
          ref="firstBallOddEven"
          :size="circleSize"
          :reverse="false"
          line-cap="square"
          :fill="fill"
          empty-fill="#d1d1d1"
          :animation-start-value="0.0"
          :start-angle="-1.57"
          insert-mode="append"
          :thickness="3"
          :show-percent="true">
          <span>{{ translations.general_even }}</span>
        </vue-circle>
        <div class="indicators">
          <div class="odd">
            <span>{{ translations.general_odd }}</span>
          </div>
          <div class="even">
            <span>{{ translations.general_even }}</span>
          </div>
        </div>
      </div>
      <div class="bet-stats" v-if="bets['8'].value">
        <div class="title"
             :title="bets['8'].name">
          {{ bets['8'].name }}
        </div>
        <div class="subtitle">
          <span>{{ translations.general_first_ball }}</span>
        </div>
        <vue-circle class="circle"
                    :progress="Number(stats.firstBallLowHigh.high)"
                    ref="firstBallLowHigh"
                    :size="circleSize"
                    :reverse="false"
                    line-cap="square"
                    :fill="fill"
                    empty-fill="#d1d1d1"
                    :animation-start-value="0.0"
                    :start-angle="-1.57"
                    insert-mode="append"
                    :thickness="3"
                    :show-percent="true">
          <span>{{ translations.general_over }}</span>
        </vue-circle>
        <div class="indicators">
          <div class="odd">
            <span>{{ translations.general_under }}</span>
          </div>
          <div class="even">
            <span>{{ translations.general_over }}</span>
          </div>
        </div>
      </div>
      <div class="bet-stats" v-if="bets['11'].value">
        <div class="title"
             :title="bets['11'].name">
          {{ bets['11'].name }}
        </div>
        <div class="subtitle">
          <LocalizedLabel translation="preballsLabel" />
        </div>
        <vue-circle
          :progress="Number(stats.preballsMoreOddEven.even)"
          ref="preballsMoreOddEven"
          :size="circleSize"
          :reverse="false"
          line-cap="square"
          :fill="fill"
          empty-fill="#d1d1d1"
          :animation-start-value="0.0"
          :start-angle="-1.57"
          insert-mode="append"
          :thickness="3"
          :show-percent="true">
          <span>{{ translations.general_even }}</span>
        </vue-circle>
        <div class="indicators">
          <div class="odd">
            <span>{{ translations.general_odd }}</span>
          </div>
          <div class="even">
            <span>{{ translations.general_even }}</span>
          </div>
        </div>
      </div>
      <div class="bet-stats" v-if="bets['6'].value">
        <div class="title"
             :title="bets['6'].name">
          {{ bets['6'].name }}
        </div>
        <div class="subtitle">
          <LocalizedLabel translation="preballsSumLabel" />
        </div>
        <vue-circle
          :progress="Number(stats.preballsSumLowHigh.high)"
          ref="preballsSumLowHigh"
          :size="circleSize"
          :reverse="false"
          line-cap="square"
          :fill="fill"
          empty-fill="#d1d1d1"
          :animation-start-value="0.0"
          :start-angle="-1.57"
          insert-mode="append"
          :thickness="3"
          :show-percent="true">
          <span>{{ translations.general_over }}</span>
        </vue-circle>
        <div class="indicators">
          <div class="odd">
            <span>{{ translations.general_under }}</span>
          </div>
          <div class="even">
            <span>{{ translations.general_over }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="number-bet-stats">
      <div class="drawn-numbers">
        <div class="title">
          <span>{{ translations.general_hot_numbers }}</span>
        </div>
        <div class="subtitle">
          <span>{{ translations.mostDrawn }}</span>
        </div>
        <div class="balls">
          <div v-for="(value, key) in mostDrawnNumbers" :key="key">
            <div>
              <BingoGridButton
                variation="circle"
                :color="bingoColors[getBallColor(value.ball)]"
                :active="true"
                :label="value.ball"
              />
            </div>
            <span class="count">
              {{ value.count }}
            </span>
          </div>
        </div>
      </div>
      <div class="drawn-numbers">
        <div class="title">
          <span>{{ translations.general_cold_numbers }}</span>
        </div>
        <div class="subtitle">
          <span>{{ translations.leastDrawn }}</span>
        </div>
        <div class="balls">
          <div v-for="(value, key) in leastDrawnNumbers" :key="key">
            <div>
              <BingoGridButton
                variation="circle"
                :color="bingoColors[getBallColor(value.ball)]"
                :active="true"
                :label="value.ball"
              />
            </div>
            <span class="count">
              {{ value.count }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="color-bet-stats">
      <div class="bet-stats">
        <div class="title">
          <span>
            {{ translations.general_color_frequency }}
          </span>
        </div>
        <div class="subtitle">
          <span>{{ translations.firstBall }}</span>
        </div>
        <div class="colors">
          <div class="color" :key="index"
               v-for="(value, key, index) in firstBallColor">
            <div class="color-title">
              <span>{{ translations[`luckysix_color_${index}`] }}</span>
            </div>
            <div class="card"
                 :class="`color-group-${index}`">
              <ColorCheckbox
                :color="bingoColors[index]"
              />
            </div>
            <div class="color-count">{{value}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { trimEnd } from 'lodash';
import { ColorCheckbox, BingoGridButton } from '@nsftx/games-sdk-js';
import VueCircle from 'vue2-circle-progress/src/index';
import hslToHex from '../utility/hslToHex';
import ballColor from '../utility/ballColor';

export default {
  name: 'Stats',
  components: {
    VueCircle,
    ColorCheckbox,
    BingoGridButton,
  },
  data() {
    return {
      circleSize: this.channelType === 'terminal' ? 154 : 120,
      completedSteps: 50,
      startAngle: '-1.57',
      totalSteps: 100,
      statsData: {},
      firstBEven: 0,
    };
  },
  computed: {
    ...mapGetters([
      'stats',
      'appConfig',
      'config',
      'bingoColors',
      'gameChannelType',
      'activeGameLayout',
      'translations',
    ]),
    mostDrawnNumbers() {
      return this.stats.drawnMostTimes;
    },
    leastDrawnNumbers() {
      return this.stats.drawnLeastTimes;
    },
    firstBallColor() {
      return this.stats.firstBallColor;
    },
    bets() {
      return this.config.bets;
    },
    fill() {
      const baseColors = this.config.ui[this.config.ui.config.baseColor];
      const h = baseColors['--primary-hue'];
      const s = trimEnd(baseColors['--primary-saturation'], '%');
      const l = trimEnd(baseColors['--primary-lightness'], '%');
      return {
        color: hslToHex.convert(h, s, l),
      };
    },
    channelType() {
      return this.gameChannelType.toLowerCase();
    },
  },
  methods: {
    getBallColor(ball) {
      return ballColor.getColorId(ball);
    },
  },
  watch: {
    stats(value) {
      if (Object.keys(this.$refs).length) {
        this.$refs.firstBallOddEven.updateProgress(parseInt(value.firstBallOddEven.even, 10));
        this.$refs.firstBallLowHigh.updateProgress(parseInt(value.firstBallLowHigh.high, 10));
        this.$refs.preballsMoreOddEven.updateProgress(parseInt(value.preballsMoreOddEven.even, 10));
        this.$refs.preballsSumLowHigh.updateProgress(parseInt(value.preballsSumLowHigh.high, 10));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .stats-wrapper {
    float: left;
    width: 100%;
    background: var(--card);
    .circle {
      color: var(--text-primary-1);
    }
    .title {
      color: var(--text-primary-1);
      font-weight: 500;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .bet-stats {
      .title {
        line-height: 1.3;
      }

      .subtitle {
        text-transform: uppercase;
        line-height: 1.3;
      }
    }

    .subtitle {
      color: var(--text-primary-2);
      font-size: 14px;
      margin-bottom: 10px;
    }

    .toggle-bet-stats {
      display: flex;
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .bet-stats {
        width: calc(100% / 5);
        margin: 2%;
        text-align: center;

        .indicators {
          float: left;
          width: 100%;
          margin-top: 8px;

          .odd, .even {
            width: 15px;
            height: 15px;
            color: var(--text-primary-2);
            font-size: 14px;
            border-radius: 2px;
          }

          .odd {
            float: left;
            background-color: var(--text-primary-1);

            span {
              float: left;
              padding: 0 1.5em;
            }
          }

          .even {
            float: right;
            background-color: var(--primary);

            span {
              float: right;
              padding: 0 1.5em;
            }
          }
        }
      }
    }

    .number-bet-stats {
      float: left;
      width: 100%;
      margin-top: 16px;

      .drawn-numbers {
        float: left;
        width: 50%;
        height: 100%;
        margin-bottom: 16px;

        .title, .subtitle {
          float: left;
          width: 100%;
          text-align: center;
        }

        .subtitle {
          text-transform: uppercase;
        }

        .balls {
          display: flex;
          position: relative;
          width: 100%;
          justify-content: center;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;

          ::v-deep {
            .button {
              margin: 0 4px 8px 4px;
              cursor: default;
            }
          }
          .count {
            color: var(--text-primary-2);
          }
        }
      }
    }

    .color-bet-stats {
      float: left;
      width: 100%;

      .colors {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;

        .card {
          position: relative;
          border: none;
          outline: none;
          color: #e6e6e6;
          background-color: var(--button);
          border-radius: 2px;
          height: 40px;
          cursor: default;

          ::v-deep {
            .color-checkbox {
              pointer-events: none;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }

        .color {
          color: var(--text-primary-2);
          margin: 4px;
          flex-basis: 60px;

          .color-title {
            color: var(--text-primary-2);
            font-size: 0.875em;
            text-align: center;
            margin-bottom: 8px;
          }

          .color-count {
            font-weight: 500;
            text-align: center;
            margin-top: 8px;
          }
        }
      }
    }

    &.terminal {
      display: grid;
      align-items: center;

      .toggle-bet-stats {
        display: grid;
        grid-template-columns: repeat(4, 154px);
        order: 2;
        justify-content: center;
        grid-gap: 158px;
        margin-bottom: 36px;

        .bet-stats {
          width: 100%;
          margin: 0;

          .title {
            font-size: 16px;
            overflow: unset;
          }
          .subtitle {
            font-size: 18px;
          }
        }
      }
      .number-bet-stats {
        order: 1;
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        margin-bottom: 36px;
        .drawn-numbers{
          margin: 0;
          height: auto;
          width: 100%;
          .title {
            font-size: 16px;
          }
          .subtitle {
            font-size: 18px;
          }
          .balls {
            .button {
              width: 72px;
              height: 72px;
              line-height: 72px;
              font-size: 28px;
            }
          }
        }
      }
      .color-bet-stats {
        order: 3;
        .bet-stats {
          margin-top: 0;
          .title {
            font-size: 16px;
            margin-bottom: 16px;
          }
          .colors {
            .color {
              font-size: 20px;
              .card {
                width: 114px;
                height: 72px;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 320px) and (max-width: 660px) {
    .stats-wrapper {
      .toggle-bet-stats {
        .bet-stats {
          width: 47%;
          margin: 18px 0;
        }
      }
      .number-bet-stats {
        flex-wrap: wrap;
        .drawn-numbers {
          width: 100%;
        }
      }
      .color-bet-stats {
        text-align: center;

        .colors {
          justify-content: space-between;
          .color {
            flex-basis: 80px;
            margin: 0;
          }
        }
      }
    }
  }

  @media (min-width: 416px) and (max-width: 510px) {
    .stats-wrapper {
      .color-bet-stats {
        .colors {
          .color {
            flex-basis: 99px;
          }
        }
      }
    }
  }

  @media (min-width: 511px) and (max-width: 626px) {
    .stats-wrapper {
      .color-bet-stats {
        .colors {
          .color {
            flex-basis: 120px;
            margin: 1px;
          }
        }
      }
    }
  }

  @media (min-width: 626px) and (max-width: 640px) {
    .stats-wrapper {
      .color-bet-stats {
        .colors {
          .color {
            flex-basis: 120px;
            margin: 3px;
          }
        }
      }
    }
  }

  @media (min-width: 320px) and (max-width: 960px) {
    .stats-wrapper {
      float: left;
    }
  }

  @media (min-width: 1268px) {
    .stats-wrapper {
      &.expanded {
        .toggle-bet-stats {
          .bet-stats {
            width: 45%;
            margin: 2%;
          }
        }
      }
      &.compact {
        .toggle-bet-stats {
          .bet-stats {
            width: 20%;
            margin: 2%;
          }
        }
      }
      .number-bet-stats {
        .drawn-numbers {
          width: 100%;
        }
      }
    }
  }

  @media (min-width: 1484px) {
    .stats-wrapper {
      .color-bet-stats {
        .colors {
          .color {
            flex-basis: 80px;
            margin: 5px;
          }
        }
      }
    }
  }
</style>
