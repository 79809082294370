import { upperFirst, template } from 'lodash';
import http from './http';

export default {
  authSevenUser(config, token, playerId) {
    const { tenantId, platformDataCenter } = config;
    const headers = {
      Authorization: `Bearer ${token}`,
      'HTTP-X-SEVEN-CLUB-UUID': tenantId,
      'HTTP-X-NAB-DP': upperFirst(config.clientplatform) || 'Web',
      'SEVEN-LOCALE': config.locale,
      'SEVEN-TP-TOKEN': token,
    };
    const path = '/users/b2b/authenticate.json';
    const url = template(process.env.VUE_APP_SEVEN_API)({
      dc: platformDataCenter ? `-${platformDataCenter}` : '',
    });
    const authData = {
      id: playerId,
      token,
      authStrategy: 'token',
    };

    const options = {
      method: 'POST',
      url: `${url}${path}`,
      headers,
      data: authData,
    };

    return http(options);
  },
  sevenLoginCheck(config, currentToken) {
    const { tenantId, platformDataCenter } = config;
    const url = template(process.env.VUE_APP_SEVEN_API)({
      dc: platformDataCenter ? `-${platformDataCenter}` : '',
    });
    const headers = {
      Authorization: `Bearer ${currentToken}`,
      'HTTP-X-SEVEN-CLUB-UUID': tenantId,
      'HTTP-X-NAB-DP': upperFirst(config.clientplatform) || 'Web',
      'SEVEN-LOCALE': config.locale,
    };

    const options = {
      method: 'GET',
      url: `${url}/jwt/login_check`,
      headers,
    };

    return http(options);
  },
  getSevenBalance(config, token, clientType) {
    const { tenantId, platformDataCenter } = config;
    const headers = {
      Authorization: `Bearer ${token}`,
      'HTTP-X-SEVEN-CLUB-UUID': tenantId,
      'X-NSFT-WALLET-USERGROUP': tenantId,
      'HTTP-X-NAB-DP': config.clientPlatform,
      'SEVEN-LOCALE': config.locale,
    };
    const url = template(process.env.VUE_APP_SEVEN_API)({
      dc: platformDataCenter ? `-${platformDataCenter}` : '',
    });
    const path = clientType === 'token' ? '/wallet/b2b/loggeduser/balance' : '/web/profile/balance.json';

    const options = {
      method: 'GET',
      url: `${url}${path}`,
      headers,
    };

    return http(options);
  },
  async getJackpot(config) {
    const channelName = 'web';
    const channelId = 3;

    const response = await http.get('/open/nonstandalone', {
      baseURL: process.env.VUE_APP_GAMES_AJS_API,
      data: {
        isJackpotRequest: true,
      },
      params: {
        account: config.tenantId,
        game: config.productId,
        channel: channelId,
        location: channelName,
        betshop: channelName,
      },
    });

    return response.data;
  },
};
