<template>
  <div class="boost-manager" ref="badge">
    <div id="boostBadgeContainer" v-if="isMobile"></div>
    <div id="boostContainer"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Boost',
  computed: {
    ...mapGetters([
      'user/user',
      'isMobile',
      'config',
    ]),
    userProfile() {
      return this['user/user'];
    },
  },
  mounted() {
    const boostConfig = this.config.ui.config.boost;
    const boostPayload = {
      tenantId: boostConfig.tenantId,
      modulesTheme: boostConfig.theme,
      environment: process.env.NODE_ENV,
      modulesContainerId: 'boostContainer',
      authorizationToken: this.userProfile.auth.token,
      widget: this.isMobile ? 'widgetL6M' : 'widgetL6',
      badgeSize: 'small',
      language: this.config.locale,
    };

    if (this.userProfile.profile.id) {
      boostPayload.profileId = this.userProfile.profile.id;
    }

    if (this.isMobile) {
      boostPayload.badgeContainerId = 'boostBadgeContainer';
      this.calcBadgeStyle();
    }

    window.NSoftBoost.configure(boostPayload);
  },
  methods: {
    calcBadgeStyle() {
      const header = document.getElementsByClassName('header-options')[0];
      const { badge } = this.$refs;
      const lengthOfIcons = Array.from(header.childNodes).filter((node) => node.nodeName !== '#comment').length;
      badge.style.left = this.isMobile ? `${31 * lengthOfIcons}px` : `${8 + 34 * lengthOfIcons}px`;
      badge.style.marginLeft = '16px';
    },
  },
};
</script>

<style lang="scss" scoped>
.boost-manager {
  height: calc(852px - 40px - 12px);

  #boostBadgeContainer {
    position: absolute;
    display: flex;
    align-items: center;
    top: 5px;
    left: 115px;
  }
  #boostContainer {
    height: 100%;
  }
}

@media (max-width: 640px) {
  .boost-manager {
    position: absolute;
    top: 5px;
    height: auto;
    #boostBadgeContainer {
      position: static;
    }
    #boostContainer {
      float: left;
      width: 100%;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100vh;
      z-index: 100;
    }
  }
}
</style>
