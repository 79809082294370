<template>
  <Help :help-path="loadHelpPath()" />
</template>

<script>
import { mapGetters } from 'vuex';
import { Help, gamesStatic } from '@nsftx/games-sdk-js';

export default {
  name: 'GameHelp',
  components: {
    Help,
  },
  computed: {
    ...mapGetters([
      'config',
    ]),
    helpPath() {
      return this.config.ui.config.luckySixHelpPath;
    },
  },
  methods: {
    loadHelpPath() {
      return gamesStatic.getHelp(this.helpPath);
    },
  },
};
</script>
